import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useSelector } from 'react-redux';
// OTHER COMPONENTS
import Layout from './layout/Layout';
import Fallback from './components/Fallback';
import DashboardLayout from './layout/Contributor/DashboardLayout';
import Top from './components/Top';
import UploadProgress from './components/UploadProgress';
import PreferencePopUp from './components/PreferencePopUp';
// PRIVATE PAGES ROUTES
import ProtectedRoutes from './Route/ProtectedRoutes';
import ContributorRoutes from './Route/ContributorRoutes';
import DetailsWishList from './pages/Contributor/DetailsWishList';
import Storage from './services/local_storage/localStorage';
import NewsLetterPage from './pages/Static_pages/Newsletter';
import MaintenanceBanner from './components/MaintenanceBanner';
// import FreeDownloads from './pages/FreeDownload';
// import ReactGA from 'react-ga';

window.capitalizeFirst = str => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const PageNotFound = lazy(() => import("./pages/PageNotFound"))
// STATIC PAGES ROUTES
const AboutUs = lazy(() => import("./pages/Static_pages/AboutUs"))
const Faq = lazy(() => import("./pages/Static_pages/Faq"))
const PichaAffiliate = lazy(() => import("./pages/Static_pages/PichaAffiliate"))
const PichaArt = lazy(() => import("./pages/Static_pages/PichaArt"))
const Careers = lazy(() => import("./pages/Static_pages/Careers"))
const TermsConditions = lazy(() => import("./pages/Static_pages/TermsConditions"))
const PrivacyPolicy = lazy(() => import("./pages/Static_pages/PrivacyPolicy"))
const CookiePolicy = lazy(() => import("./pages/Static_pages/CookiePolicy"))
const Contributor = lazy(() => import("./pages/Static_pages/Contributor"))
const BecomingPichaContributor = lazy(() => import("./pages/Static_pages/BecomingPichaContributor"))
const Resources = lazy(() => import("./pages/Static_pages/Resources"))
const Grants = lazy(() => import("./pages/Static_pages/Grants"))
const Awards = lazy(() => import("./pages/Static_pages/Awards"))
const Licenseagreement = lazy(() => import("./pages/Static_pages/Licenseagreement"))
const StaticDetailPage = lazy(() => import("./pages/Static_pages/StaticDetailPage"))
const MelaninModern = lazy(() => import("./pages/Static_pages/MelaninModern"))


// PUBLIC PAGES ROUTES  
const Home = lazy(() => import("./pages/Home"))
const ContactUs = lazy(() => import("./pages/ContactUs"))
const Login = lazy(() => import("./pages/credentials/Login"))
const UserRegister = lazy(() => import("./pages/credentials/UserRegister"))
const ForgotPassword = lazy(() => import("./pages/credentials/ForgotPassword"))
const ContributorRegister = lazy(() => import("./pages/credentials/ContributorRegister"))
const JoinUs = lazy(() => import("./pages/credentials/JoinUs"))
const ResetPassword = lazy(() => import("./pages/credentials/ResetPassword"))
const EmailVerification = lazy(() => import("./pages/credentials/EmailVerification"))
const BrowseCollections = lazy(() => import('./pages/BrowseCollections'))
const Downloads = lazy(() => import('./pages/Downloads'))
const PrdoductDetail = lazy(() => import("./pages/product/PrdoductDetail"))
const Packages = lazy(() => import("./pages/Packages"))
const SearchResult = lazy(() => import("./pages/SearchResult"))
const AllData = lazy(() => import("./pages/AllData"))
const ProfileView = lazy(() => import("./pages/Contributor/ProfileView"))
const Invoice = lazy(() => import("./components/Invoice"))
const ThankYou = lazy(() => import("./pages/ThankYou"))
const CheckOut = lazy(() => import("./pages/CheckOut"))
const Subscription = lazy(() => import("./pages/credentials/Checkout-Subscription"))
const ImageBrief = lazy(() => import('./pages/ImageBrief'))
const FreeResearch = lazy(() => import('./pages/FreeResearch'))
const PopularSearches = lazy(() => import("./pages/PopularSearches"));
const ReportError = lazy(() => import("./pages/ReportError"));


const Challenges = lazy(() => import("./pages/Contributor/Challenges"))
const ChallengeDetails = lazy(() => import("./pages/Contributor/ChallengeDetails"))
const ActiveChallenges = lazy(() => import("./pages/Contributor/ActiveChallenges"))
const PastChallenges = lazy(() => import("./pages/Contributor/PastChallenges"))
const WishLists = lazy(() => import("./pages/Contributor/WishLists"))
const CreateWishList = lazy(() => import("./pages/Contributor/CreateWishList"))
const EditWishList = lazy(() => import("./pages/Contributor/EditWishList"))
const Events = lazy(() => import("./pages/Events"))
const EventDetails = lazy(() => import("./pages/EventDetails"))
const FreeDownloads = lazy(() => import("./pages/FreeDownload"))




function App() {

  // DISABLE RIGHT CLICK
  useEffect(() => {
    const handleContextmenu = e => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }
    // function ctrlShiftKey(e, keyCode) {
    //   return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
    // }
    // document.onkeydown = (e) => {
    //   // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
    //   if (
    //     e.keyCode === 123 ||
    //     ctrlShiftKey(e, 'I') ||
    //     ctrlShiftKey(e, 'J') ||
    //     ctrlShiftKey(e, 'C') ||
    //     (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0))
    //   )
    //     return false;
    // };
  }, [])

  const { isDownloading } = useSelector(item => item.fileDownload)
  const isAuth = useSelector(item => item.isAuth.value)
  const Step = Storage.getPreferenceStep();


  return (
    <>
      <Router>
      <MaintenanceBanner /> 
        <Top>
          <Suspense fallback={<Fallback />}>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route element={<Layout />}>

                {/* ..................PUBLIC PAGES............................ */}
                <Route path='/resetPassword/:token' element={<ResetPassword />} />
                <Route path='/register' element={<UserRegister />} />
                <Route path='/contributor-registration' element={<ContributorRegister />} />
                <Route path='/join-us' element={<JoinUs />} />
                <Route path='/login' element={<Login />} />
                <Route path='/forgotpassword' element={<ForgotPassword />} />
                <Route path='/emailVerification/:id/:hash' element={<EmailVerification />} />
                <Route path='/collections' element={<BrowseCollections />} />
                <Route path='/downloads/:type/:slug' element={<Downloads />} />
                <Route path='/downloads/:product' element={<PrdoductDetail />} />
                <Route path='/SearchResult' element={<SearchResult />} />
                <Route path='/enterprise' element={<Packages />} />
                <Route path='/contact' element={<ContactUs />} />
                <Route path='/allData' element={<AllData />} />
                <Route path='/contributor-login/:slug' element={<ProfileView />} />
                <Route path='/checkout' element={<CheckOut />} />
                <Route path='/subscription/:id' element={<Subscription />} />
                <Route path='/image-brief' element={<ImageBrief />} />
                <Route path='/free-research' element={<FreeResearch />} />
                <Route path='/popular-searches' element={<PopularSearches />} />
                <Route path='/events' element={<Events />} />
                <Route path='/events/detail/:id' element={<EventDetails />} />
                <Route path='/report-error' element={<ReportError />} />
                <Route path='/newsletter-sign-in' element={<NewsLetterPage />} />
                <Route path='/picha-and-mftc-summit' element={<FreeDownloads />} />



                {/* ..................STATIC PAGES............................ */}
                <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                <Route path='/cookie-policy' element={<CookiePolicy />} />
                <Route path='/contributor' element={<Contributor />} />
                <Route path='/becoming-a-picha-contributor' element={<BecomingPichaContributor />} />
                <Route path='/resources' element={<Resources />} />
                <Route path='/:slug' element={<StaticDetailPage />} />
                <Route path='/grants' element={<Grants />} />
                <Route path='/awards' element={<Awards />} />
                <Route path='/license-agreement' element={<Licenseagreement />} />
                <Route path='/about' element={<AboutUs />} />
                <Route path='/melanin-modern' element={<MelaninModern />} />
                <Route path='/faqs' element={<Faq />} />
                <Route path='/become-a-picha-affiliate' element={<PichaAffiliate />} />
                <Route path='/picha-art' element={<PichaArt />} />
                <Route path='/careers' element={<Careers />} />
                <Route path='/terms-conditions' element={<TermsConditions />} />
                <Route path='/invoice' element={<Invoice />} />



                {/*---------- PROTECTED ROUTE ----------------- */}
                <Route path="/checkout/purchase-confirmation" element={<ProtectedRoutes><ThankYou /></ProtectedRoutes>} />

                <Route path='/challenges' element={<ContributorRoutes><Challenges /> </ContributorRoutes>} />

                <Route path='/challenges/:slug' element={<ContributorRoutes><ChallengeDetails /> </ContributorRoutes>} />

                <Route path='/active-challenges' element={<ContributorRoutes><ActiveChallenges /> </ContributorRoutes>} />

                <Route path='/past-challenges' element={<ContributorRoutes><PastChallenges /> </ContributorRoutes>} />
                <Route path='/wishLists' element={<ProtectedRoutes><WishLists /> </ProtectedRoutes>} />
                <Route path='/wish-lists/create' element={<ProtectedRoutes><CreateWishList /> </ProtectedRoutes>} />
                <Route path='/wish-lists/edit/:id' element={<ProtectedRoutes><EditWishList /> </ProtectedRoutes>} />
                <Route path='/wish-lists/view/:id' element={<DetailsWishList />} />
                {/* ..................ERROR PAGE............................ */}
                <Route path="not-found" element={<PageNotFound />} />
                <Route path="*" element={<PageNotFound />} />
              </Route>

              {/*----------  Dashboard ----------------- */}
              <Route path='/contributor-dashboard/*' element={<ProtectedRoutes> <DashboardLayout /> </ProtectedRoutes>} />
              <Route path='/user-dashboard/*' element={<ProtectedRoutes> <DashboardLayout /> </ProtectedRoutes>} />



            </Routes>
          </Suspense>
          {/*---------- UPLOAD PROGRESSBAR ----------------- */}
          {
            isDownloading && <div>
              <Suspense fallback={<Fallback />}>
                <UploadProgress />
              </Suspense>
            </div>
          }
          {isAuth && Step !== null &&
            < PreferencePopUp />
          }
        </Top>


      </Router>
    </>
  );
}

export default App;
