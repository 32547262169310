import React, { useState } from 'react';
import { Alert, IconButton, Collapse } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const MaintenanceBanner = () => {
  const [open, setOpen] = useState(true);

  return (
    <Collapse in={open}>
      <Alert
        severity="warning"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="large"
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{
          position: 'fixed', // Stick to the top
          top: 0,
          left: 0,
          right: 0,
          zIndex: 11000, // Ensure it's on top
          padding: '20px', // Increase padding
          fontSize: '1.2rem', // Increase font size
          textAlign: 'center', // Center the text
          justifyContent: 'center', // Center the close icon with the text
          display: 'flex', // Flexbox for aligning items
          alignItems: 'center', // Align icon and text vertically
        }}
      >
        Our website is currently undergoing maintenance. Some features may not be available.
      </Alert>
    </Collapse>
  );
};

export default MaintenanceBanner;
